import React, { Component } from 'react'
import HerobannerSection from './HerobannerSection';
import TrustBySection from './TrustBySection';
import CTASection from './CTASection';
import FeaturesComponent from './FeaturesComponent';
import CloudComponent from './CloudComponent';
import HelpComponent from './HelpComponent';
import StartComponent from './StartComponent';
import TagManager from 'react-gtm-module'

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

class LandingComponent extends Component {

    componentDidMount() {
        this.googleAnalyticsScript();
        this.googleAnalyticsGA4Script();
        this.googleTagManager();
        this.googleAds();
    }

    googleAnalyticsScript = async () => {
        const script = await loadScript(`https://www.googletagmanager.com/gtag/js?id=UA-181390090-3`)
        if (script) {
            // window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'UA-181390090-3');

        }
    }

    googleAnalyticsGA4Script = async () => {
        const script = await loadScript(`https://www.googletagmanager.com/gtag/js?id=G-TGVX43TQ6H`)
        if (script) {
            // window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-TGVX43TQ6H');
        }
    }

    googleTagManager = () => {
        const tagManagerArgs = {
            gtmId: 'GTM-TP3TQFB'
        }

        TagManager.initialize(tagManagerArgs)
    }
    googleAds = async () => {
        const script = await loadScript(`https://www.googletagmanager.com/gtag/js?id=AW-499406963`)
        if (script) {
            // window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'AW-499406963');

        }
    }

    render() {
        return (
            <>
                <HerobannerSection />
                <TrustBySection />
                {/* <PricingSection /> */}
                <CTASection />
                <FeaturesComponent />
                <CloudComponent />
                <HelpComponent />
                <StartComponent />
            </>
        )
    }
}

export default LandingComponent
