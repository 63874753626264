import React, { Component } from 'react'
import './LandingComponent.css';
import HelpImg from '../../images/help-image.svg';
import { utilsController } from '../../controller/landing.controller';

export default class HelpComponent extends Component {
    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            smallScreen: false,
            utilsArray: [],
            displayObject: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getUtilsContent();
        this.checkWindowSize();
        window.addEventListener('resize', this.checkWindowSize)
    }

    componentWillUnmount() {
        this._unmounted = false;
        window.removeEventListener('resize', this.checkWindowSize)
    }

    checkWindowSize = () => {
        if (this._unmounted) {
            this.setState({
                smallScreen: window.innerWidth < 1023
            })
        }
    }

    getUtilsContent = async () => {
        const res = await utilsController();
        // console.log(res);
        if (this._unmounted) {
            this.setState({
                utilsArray: res
            })
        }
    }

    displayContent = (obj) => {
        // console.log(obj);
        if (this._unmounted) {
            this.setState({
                displayObject: obj
            })
        }
    }

    render() {

        const { utilsArray, displayObject, smallScreen } = this.state;

        return (
            <section className={'help_section'}>
                <div className="container">
                    <div className={'help_wrap'}>
                        <div className={`help_block max_413`}>
                            <h2 className={`subTitle textLeft max_357`}><span>FEATURES</span>See how DocQuik can help</h2>
                            <ul className={'help_list'}>
                                {utilsArray.length > 0 ?
                                    utilsArray.map((info, i) =>
                                        smallScreen ?
                                            <li key={i}>
                                                <h5>{info.title}</h5>
                                                <p>{info.description}</p>
                                            </li>
                                            :
                                            <li key={i} onMouseEnter={() => this.displayContent(info)} onMouseLeave={() => this.displayContent(null)}>
                                                <h5>{info.title}</h5>
                                                <p className={'limit'}>{info.description}</p>
                                            </li>)
                                    :
                                    null
                                }
                                {/* <li>
                                    <h5>Multiple Login Methods</h5>
                                    <p>Docquicksupports multiple login methods. You can login using your Google or Yahoo or Microsoft Account.</p>
                                </li>
                                <li>
                                    <h5>Task Manager</h5>
                                    <p>Docquick has an intelligent business process application known as task manager for rapidly designing, implementing, deploying and optimizing enterprise solutions</p>
                                </li> */}
                            </ul>
                        </div>
                        <div className={`help_block help_block_img`}>
                            <div className={'img_wrap'}>
                                <img src={HelpImg} alt="See how DocQuik can help" />
                            </div>
                            {smallScreen === false ? displayObject ?
                                <ul className={'review_ul'}>

                                    <li><p>{displayObject.description}</p></li>
                                    {/* <li><p>Your favorite files will also be easy to locate on your personal dashboard. They display when you select the My Favorites tab on the My Documents screen.</p></li> */}
                                    {/* <li><p>You can favorite a file in the recent files list screen also. Further, you can setup favorites even from the search menu after searching for the file.</p></li>
                                    <li><p>It supports both structured and dynamic (unstructured, non-routine, unexpected, situational ) work interactions.</p></li> */}
                                </ul>
                                :
                                null
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
