import { apiHelper } from "../helper/api.helper";

export const landingIntroductions = async () =>{
    const res = await apiHelper('landing-introductions', 'GET', null, null);
    if(res.status !== 200){
        return [];
    }

    return res.data
}

export const customerReviews = async () =>{
    const res = await apiHelper('customer-reviews', 'GET', null, null);
    if(res.status !== 200){
        return [];
    }

    return res.data
}

export const trustedApi = async () =>{
    const res = await apiHelper('advt-brands', 'GET', null, null);
    if(res.status !== 200){
        return [];
    }

    return res.data
}

export const featuresController = async () =>{
    const res = await apiHelper('landing-features', 'GET', null, null);
    if(res.status !== 200){
        return [];
    }

    return res.data
}

export const CTAController = async () =>{
    const res = await apiHelper('advt-contents', 'GET', null, null);
    if(res.status !== 200){
        return [];
    }

    return res.data
}
export const utilsController = async () =>{
    const res = await apiHelper('utils', 'GET', null, null);
    if(res.status !== 200){
        return [];
    }

    return res.data
}
export const getTaglines = async () =>{
    const res = await apiHelper('doc-quik-taglines', 'GET', null, null);
    if(res.status !== 200){
        return [];
    }

    return res.data
}