import React, { Component } from 'react';
import './LandingComponent.css';

export default class StartComponent extends Component {
    render() {
        return (
            <section className={'start_section'}>
                <div className="container">
                    <div className={'start_wrap'}>
                        <h5>Let's start with Us</h5>
                        <p>A simple and intuitive user interface for users having full control of their data and information, optimize any process and facilitate business interactions. Single platform for the functions of document management, storage, linking and sharing.</p>
                        <a href="https://docquik.io/pricing" target="_blank" rel="noopener noreferrer" className={'btn_start'}>Get Started</a>
                    </div>
                </div>
            </section>
        )
    }
}
