import React, { Component } from 'react';
import { CTAController } from '../../controller/landing.controller';
import bgImg from '../../images/cat-banner.png'
import './LandingComponent.css';

export default class CTASection extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            content: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getCTAContent()
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getCTAContent = async () => {
        const res = await CTAController();
        // console.log(res);
        if (this._unmounted) {
            this.setState({
                content: res[0]
            })
        }
    }

    render() {

        const { content } = this.state;

        return (
            <section className={"CTASection"} style={{ backgroundImage: `url(${content ? content.image.url : bgImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: ' center center', backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className={"cta_wrap"}>
                        <h2>{content && content.title}</h2>
                        <p>{content && content.description}</p>
                        <a href="https://docquik.io/pricing" target="_blank" rel="noopener noreferrer" className={"btn_tryNow"}>Try Now</a>
                    </div>
                </div>
            </section>
        )
    }
}
