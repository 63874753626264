import React, { Component } from 'react';
import CloudImg from '../../images/cloud-img.svg';
import './LandingComponent.css';

export default class CloudComponent extends Component {
    render() {
        return (
            <section className={'cloud_section'}>
                <div className={`container`}>
                    <div className={'cloud_wrap'}>
                        <h2 className={`subTitle2 max_522`}>Cloud Document Management System Software</h2>
                        <p className={`subTitle2_text max_732`}>An AI based multi user document management solution(DMS) that allows businesses to securely organize content. Fully featured DMS system on the cloud, used to receive, track, manage and store documents, provide task management and reduce paper.</p>
                        <div className={'image_cloud'}>
                            <img src={CloudImg} alt="Cloud Document Management System Software" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
