import React, { Component } from 'react';
// import HooliLogo from '../../images/hooli_logo.svg';
// import EmberLogo from '../../images/ember_logo.svg';
// import DiscoverLogo from '../../images/discover_logo.svg';
// import DHILogo from '../../images/dhl_logo.svg';
// import SASSLogo from '../../images/sass_logo.svg';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { trustedApi } from '../../controller/landing.controller';
// import pageStyle from '../../styles/LandingPage.module.css'
SwiperCore.use([]);
export default class TrustBySection extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            trustedArray: []
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getBrands()
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getBrands = async () =>{
        const res = await trustedApi();
        // console.log(res);
        if(this._unmounted){
            this.setState({
                trustedArray:res
            })
        }
    }

    render() {

        const { trustedArray } = this.state;
        const breakpoints = {
            "320": {
                "slidesPerView": 2,
                "spaceBetween": 20
            },
            "640": {
                "slidesPerView": 3,
                "spaceBetween": 20
            },
            "768": {
                "slidesPerView": 4,
                "spaceBetween": 40
            },
            "1024": {
                "slidesPerView": 5,
                "spaceBetween": 50
            }
        }

        return (
            <section className={'trust_wrap'}>
                <div className="container">
                    <h2 className={'subTitle'}>Trusted by Brands</h2>
                    <ul className={'trust_ul'}>
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={5}
                            className={'trustSlider'}
                            loop={true}
                            breakpoints={breakpoints}

                        >
                            {trustedArray.length > 0 ?
                                trustedArray.map((info, i) =>
                                    <SwiperSlide key={i}>
                                        <li><img src={info?.icon?.url} alt={'Brands'} /></li>
                                    </SwiperSlide>)
                                :
                                null
                            }

                            {/* <li><img src={EmberLogo} alt="Ember" /></li>
                            <li><img src={DiscoverLogo} alt="Discover" /></li>
                            <li><img src={DHILogo} alt="DHL" /></li>
                            <li><img src={SASSLogo} alt="Sass" /></li> */}
                        </Swiper>
                    </ul>
                </div>
            </section>
        )
    }
}
