import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingComponent from '../components/LandingComponent';
import HeaderComponent from '../components/common/HeaderComponent';
import FooterComponent from '../components/common/FooterComponent';

class RouterComponent extends Component {
    render() {
        return (
            <>
                <HeaderComponent />
                <Switch>
                    <Route exact path="/" component={LandingComponent} />
                </Switch>
                <FooterComponent />
            </>
        )
    }
}

export default RouterComponent
