import axios from 'axios';

export const apiHelper = (endpoint, method, data, headers) =>{

    const api = `https://cms.docquik.io/${endpoint}`;

    return new Promise((resolve, reject)=>{
        return axios({
            url:api,
            method,
            data,
            headers,
        })
        .then((res)=>resolve(res))
        .catch((err)=>reject(err));
    })
}