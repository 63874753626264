import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './HeaderComponent.css';
import MailIcon from '../../../images/mail-icon.svg';
import Logo from '../../../images/Logo.svg';
import { contactDetails } from '../../../controller/contact.controller';

class HeaderComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isSmallScreen: false,
            isOpenNav: false,
            isSticky: false,
            isPadd: false,
            email:'',
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getContactDetails();
        if (window.pageYOffset > 220) {
            if (this._unmounted) {
                this.setState({
                    isSticky: true
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    isSticky: false
                })
            }
        }
        window.addEventListener('resize', this.checkWindowSize)
        window.addEventListener('scroll', this.checkStickyHeader)
    }

    componentWillUnmount() {
        this._unmounted = false;
        window.removeEventListener('resize', this.checkWindowSize)
        window.removeEventListener('scroll', this.checkStickyHeader)
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(this.state.isOpenNav);
        if (nextState.isOpenNav !== this.state.isOpenNav) {
            if (nextState.isOpenNav) {
                this.setState({
                    isPadd: true
                });
                this.checkStickyHeader();
            }
        }
        return true;
    }

    getContactDetails = async () =>{
        const res = await contactDetails();
        if(res && Array.isArray(res) && res.length > 0){
            if(this._unmounted){
                this.setState({
                    email:res[0].email
                })
            }
        }
        // console.log(res);
    }

    checkStickyHeader = () => {
        if (!this.state.isOpenNav) {
            // console.log('if if')
            if (window.pageYOffset > 220) {
                if (this._unmounted) {
                    this.setState({
                        isSticky: true
                    })
                }
            } else {
                if (this._unmounted) {
                    this.setState({
                        isSticky: false
                    })
                }
            }
        }else{
            if (this._unmounted) {
                this.setState({
                    isSticky: true
                })
            }
        }
    }

    checkWindowSize = () => {
        if (window.screen.width < 768) {
            if (this._unmounted) {
                this.setState({
                    isSmallScreen: true
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    isSmallScreen: false
                })
            }
        }
    }

    toggleNav = () => {
        if (this.state.isOpenNav) {
            this.setState({
                isPadd: false,
            });
            
        } 
        
        this.setState(state => ({
            isOpenNav: !state.isOpenNav,
        }))
    }

    render() {

        const { isOpenNav, isSticky, isPadd } = this.state;
        // console.log(isOpenNav);
        const navClass = isOpenNav ? `navbar_collapse active` : `navbar_collapse`
        const stickyHeaderClass = isSticky || isOpenNav ? `main_header sticky` : `main_header`
        return (
            <header className={'headerSection'}>
                {!isOpenNav || isSticky ? <div className="container">
                    <div className={'top_header'}>
                        <h6>Have any questions?</h6>
                        <a href={`mailto:${this.state.email}`}>
                            <img src={MailIcon} alt="Email icon" /> <span>{this.state.email}</span></a>
                    </div>
                </div> : null}
                <nav className={isPadd || !isOpenNav ? `${stickyHeaderClass} isPadd ` : `${stickyHeaderClass}`}>
                    <div className="container">
                        <div className={'navBar_wrap'}>
                            <div className={'logo_wrap'}>
                                <Link to="/">
                                    <img src={Logo} alt="DocQuik" />
                                </Link>
                                <button type="button" className={'navbar_toggler'} onClick={this.toggleNav}>
                                    <span className={'icon_toggler'}></span>
                                </button>
                            </div>
                            <div className={navClass}>
                                <ul className={'navbar_nav'}>
                                    {/* <li><ActiveLink activeClassName={`${headerStyle.active}`} href="/features"><a>Features</a></ActiveLink></li>
                                    <li><ActiveLink activeClassName={`${headerStyle.active}`} href="/pricing"><a>Pricing</a></ActiveLink></li>
                                    <li><ActiveLink activeClassName={`${headerStyle.active}`} href="/industries"><a>Industries</a></ActiveLink></li>
                                    <li><ActiveLink activeClassName={`${headerStyle.active}`} href="/knowledgebase"><a>Knowledgebase</a></ActiveLink></li>
                                    <li><ActiveLink activeClassName={`${headerStyle.active}`} href="/contact-us"><a>Contact Us</a></ActiveLink></li> */}
                                    <li className={'btns'}><a href="https://docquik.io/demo-videos" target="_blank" rel="noopener noreferrer" className={`btn_nav btn_outline_demo`}>Demo Videos</a></li>
                                    <li className={'btns'}><a href="https://docquik.io/pricing" target="_blank" rel="noopener noreferrer" className={`btn_nav btn_yellow`}>Sign Up</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {isOpenNav ? <div className={'overlay_bg'} onClick={this.toggleNav}></div> : null}
                </nav>
            </header>
        )
    }
}

export default HeaderComponent;
