import React, { Component } from "react";
import FooterLogo from "../../../images/footer-logo.svg";
import "./FooterComponent.css";
import { getTaglines } from '../../../controller/landing.controller';
class FooterComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            tagline: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getTagline()
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getTagline = async () => {
        const res = await getTaglines();
        if (this._unmounted) {
            this.setState({
                tagline: res[0].tag
            })
        }
    }
  render() {
    const { tagline } = this.state;
    return (
      <footer className={"footer_bg"}>
        <div className="container">
          <div className={"footer_top"}>
            <div className={"logo__footer_wrap"}>
              <img src={FooterLogo} alt="DocQuik" />
              <p>{tagline}</p>
            </div>
            <div className={"wrap_links"}>
              <ul className={"link_block"}>
                <li>
                  <a
                    href="https://docquik.io/features"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Feature
                  </a>
                </li>
                <li>
                  <a
                    href="https://docquik.io/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://docquik.io/blogs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blogs
                  </a>
                </li>
              </ul>
              <ul className={"link_block"}>
                <li>
                  <a
                    href="https://docquik.io/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="https://docquik.io/tutorials"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tutorials
                  </a>
                </li>
                <li>
                  <a
                    href="https://docquik.io/policies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Policies
                  </a>
                </li>
              </ul>
              <ul className={"link_block"}>
                <li>
                  <a
                    href="https://docquik.io/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={"footer_bottom"}>
            <p>All rights reserved © Docquik 2021</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterComponent;
