
import 'bootstrap/dist/css/bootstrap.min.css';
import RouterComponent from './RouterComponent';
import './App.css';
import LiveChat from 'react-livechat';
// import TagManager from 'react-gtm-module';
// import ReactGA from 'react-ga';
import { Component } from 'react';

// const tagManagerArgs = {
//   gtmId: 'GTM-WLGW5X2'
// }
// const tagManagerADSArgs = {
//   gtmId: 'AW-499406963'
// }

// function loadScript(src) {
//   return new Promise((resolve) => {
//       const script = document.createElement("script");
//       script.src = src;
//       script.onload = () => {
//           resolve(true);
//       };
//       script.onerror = () => {
//           resolve(false);
//       };
//       document.body.appendChild(script);
//   });
// }

class App extends Component {

  componentDidMount = async () => {
    // Google Tag Manager
    // TagManager.initialize(tagManagerArgs)
    // Google Tag Manager over

    // TagManager.initialize(tagManagerADSArgs)
    // ReactGA.initialize('UA-181390090-2');
    // ReactGA.initialize('G-D2G1G4ZEH0');

    // Google Analytics UA
    // await loadScript(`https://www.googletagmanager.com/gtag/js?id=${'UA-181390090-2'}`)
    // Google Analytics UA over

    // Google Ads Tag
    // await loadScript(`https://www.googletagmanager.com/gtag/js?id=${'AW-499406963'}`)
    // Google Ads Tag over

    // Google Analytics GA4
    // await loadScript(`https://www.googletagmanager.com/gtag/js?id=${'G-D2G1G4ZEH0'}`)
    // Google Analytics GA4 over

    // console.log(adwords)
  }

  render() {
    return (
      <>
        <RouterComponent />
        <LiveChat license={12794592} />
      </>
    );
  }
}

export default App;
