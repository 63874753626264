import React, { Component } from "react";
import heroBanner from "../../images/hero-image.svg";
import ReactStars from "react-rating-stars-component";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import { Link } from 'react-router-dom';
import "./LandingComponent.css";
import {
  customerReviews,
  landingIntroductions,
} from "../../controller/landing.controller";

SwiperCore.use([Pagination, Autoplay, Navigation]);

export default class HerobannerSection extends Component {
  _unmounted = false;
  constructor(props) {
    super(props);
    this.state = {
      mainSlider: [],
      reviewSlider: [],
    };
  }

  componentDidMount() {
    this._unmounted = true;
    this.getIntro();
    this.getReviews();
  }

  componentWillUnmount() {
    this._unmounted = false;
  }

  getIntro = async () => {
    const res = await landingIntroductions();
    if (this._unmounted) {
      this.setState({
        mainSlider: res,
      });
    }
  };

  getReviews = async () => {
    const res = await customerReviews();
    // console.log(res);
    if (this._unmounted) {
      this.setState({
        reviewSlider: res,
      });
    }
  };

  render() {
    const { mainSlider, reviewSlider } = this.state;

    return (
      <section className={"bannerSection"}>
        <div className={"banner_img_wrap"}></div>
        <div className="container">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={false}
            pagination={{ clickable: true }}
            className={"homeSlider"}
            loop={true}
          >
            {mainSlider.length > 0
              ? mainSlider.map((info, i) => (
                  <SwiperSlide key={i}>
                    <div className={"banner_wrap"}>
                      <div className={"banner_content_wrap"}>
                        <h1>{info.title}</h1>
                        <div className={"review_wrap"}>
                          <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={false}
                            pagination={false}
                            className={"reviweSlider"}
                            loop={true}
                            autoplay={{
                              delay: 3500,
                            }}
                          >
                            {reviewSlider.length > 0
                              ? reviewSlider.map((review, index) => (
                                  <SwiperSlide key={index}>
                                    <div className={"rating_wrap"}>
                                      <ReactStars
                                        count={5}
                                        value={review.star}
                                        edit={false}
                                        size={24}
                                        activeColor="#006388"
                                      />
                                    </div>
                                    <p>
                                      {review.comment}
                                      <span>- {review.user_name}.</span>
                                    </p>
                                  </SwiperSlide>
                                ))
                              : null}
                          </Swiper>
                        </div>
                        <p className={"text_banner"}>{info.description}</p>
                        <a
                          href="https://docquik.io/demo-videos"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`btn_demoVideo btnBg_white`}
                        >
                          Demo Videos
                        </a>
                      </div>
                      <div className={"hero_img_wrap"}>
                        {/* info.image[0].url */}
                        <img
                          src={
                            info.image?.[0]?.url
                              ? info.image?.[0]?.url
                              : heroBanner
                          }
                          alt="Technology that Works with Docquik"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </section>
    );
  }
}
