import React, { Component } from 'react';
// import AiIcon from '../../images/ai-icon.svg';
// import SecurityIcon from '../../images/security-icon.svg';
// import NotificationIcon from '../../images/notification-icon.svg';
// import CloudIcon from '../../images/cloud-icon.svg';
import './LandingComponent.css';
import { featuresController } from '../../controller/landing.controller';

export default class FeaturesComponent extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            featureSlider: [],
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getFeature();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }


    getFeature = async () => {
        const res = await featuresController();
        // console.log(res);
        if (this._unmounted) {
            this.setState({
                featureSlider: res
            })
        }
    }

    render() {

        const { featureSlider } = this.state;

        return (
            <section className={'feature_section'}>
                <div className="container">
                    <h2 className={'subTitle'}><span>BENEFITS</span>Our features</h2>
                    <ul className={'feature_wrap'}>
                        {featureSlider.length > 0 ?
                            featureSlider.map((info, i) =>
                                <li key={i}>
                                    <div className={'feature_block'}>
                                        <div className="img_wrp">
                                            <img src={info?.icon?.[0]?.url} alt="Core AI Intelligence" />
                                        </div>
                                        <h4>{info.title}</h4>
                                        <p>{info.description}</p>
                                    </div>
                                </li>)
                            :
                            null
                        }
                        {/* <li>
                            <div className={'feature_block'}>
                                <img src={SecurityIcon} alt="Enhanced Security" />
                                <h4>Enhanced Security</h4>
                                <p>{`Docquick has user & group based access control alonwith system level rights, giving you the power to share & control access to socuments in your repository along-with audit trail and activity email notifications helping administrators to review actions.`}</p>
                            </div>
                        </li>
                        <li>
                            <div className={'feature_block'}>
                                <img src={NotificationIcon} alt="Notifications" />
                                <h4>Notifications</h4>
                                <p>Document Notifications keep people informed about document changes, routing of documents for reviews and approvals, and more. Notifications can be receives via email or in the built-in app, or both.</p>
                            </div>
                        </li>
                        <li>
                            <div className={'feature_block'}>
                                <img src={CloudIcon} alt="User-Friendly Cloud based DSM" />
                                <h4>User-Friendly Cloud based DSM</h4>
                                <p>An AI based multi user document management solution(DMS) that allows businesses to securely organize content. Fully featured DMS system on the cloud, used to receive, track, manage and store documents, provide task management and reduce paper.</p>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </section>
        )
    }
}
